const complexCalTable = {
  calculation: [
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7", "row-2", "row-8", "row-6"],
        sourceCols: [2],
        targetRows: ["row-25"],
        targetCols: [2]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7", "row-2", "row-8"],
        sourceCols: [1],
        targetRows: ["row-25"],
        targetCols: [1]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7", "row-2", "row-8", "row-6"],
        sourceCols: [3],
        targetRows: ["row-25"],
        targetCols: [3]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7", "row-2", "row-8", "row-6"],
        sourceCols: [4],
        targetRows: ["row-25"],
        targetCols: [4]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7", "row-2", "row-8", "row-6"],
        sourceCols: [5],
        targetRows: ["row-25"],
        targetCols: [5]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7", "row-2", "row-8", "row-6"],
        sourceCols: [6],
        targetRows: ["row-25"],
        targetCols: [6]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7", "row-2", "row-8", "row-6"],
        sourceCols: [7],
        targetRows: ["row-25"],
        targetCols: [7]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-7", "row-2", "row-8", "row-6"],
        sourceCols: [9],
        targetRows: ["row-25"],
        targetCols: [9]
      }
    },
    // end of the phase 1
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-2", "row-11"],
        sourceCols: [1],
        targetRows: ["row-26"],
        targetCols: [1]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-2", "row-11"],
        sourceCols: [2],
        targetRows: ["row-26"],
        targetCols: [2]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-2", "row-11"],
        sourceCols: [3],
        targetRows: ["row-26"],
        targetCols: [3]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-2", "row-11"],
        sourceCols: [4],
        targetRows: ["row-26"],
        targetCols: [4]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-2", "row-11"],
        sourceCols: [5],
        targetRows: ["row-26"],
        targetCols: [5]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-2", "row-11"],
        sourceCols: [6],
        targetRows: ["row-26"],
        targetCols: [6]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-2", "row-11"],
        sourceCols: [7],
        targetRows: ["row-26"],
        targetCols: [7]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-10", "row-2", "row-11"],
        sourceCols: [9],
        targetRows: ["row-26"],
        targetCols: [9]
      }
    },
    // current year
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15", "row-2", "row-16"],
        sourceCols: [1],
        targetRows: ["row-28"],
        targetCols: [1]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15", "row-2", "row-16", "row-14"],
        sourceCols: [2],
        targetRows: ["row-28"],
        targetCols: [2]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15", "row-2", "row-16", "row-14"],
        sourceCols: [3],
        targetRows: ["row-28"],
        targetCols: [3]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15", "row-2", "row-16", "row-14"],
        sourceCols: [4],
        targetRows: ["row-28"],
        targetCols: [4]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15", "row-2", "row-16", "row-14"],
        sourceCols: [5],
        targetRows: ["row-28"],
        targetCols: [5]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15", "row-2", "row-16", "row-14"],
        sourceCols: [6],
        targetRows: ["row-28"],
        targetCols: [6]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15", "row-2", "row-16", "row-14"],
        sourceCols: [7],
        targetRows: ["row-28"],
        targetCols: [7]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-15", "row-2", "row-16", "row-14"],
        sourceCols: [9],
        targetRows: ["row-28"],
        targetCols: [9]
      }
    },
    // depration
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-2", "row-19"],
        sourceCols: [1],
        targetRows: ["row-29"],
        targetCols: [1]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-2", "row-19"],
        sourceCols: [2],
        targetRows: ["row-29"],
        targetCols: [2]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-2", "row-19"],
        sourceCols: [3],
        targetRows: ["row-29"],
        targetCols: [3]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-2", "row-19"],
        sourceCols: [4],
        targetRows: ["row-29"],
        targetCols: [4]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-2", "row-19"],
        sourceCols: [5],
        targetRows: ["row-29"],
        targetCols: [5]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-2", "row-19"],
        sourceCols: [6],
        targetRows: ["row-29"],
        targetCols: [6]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-2", "row-19"],
        sourceCols: [7],
        targetRows: ["row-29"],
        targetCols: [7]
      }
    },
    {
      source: "vertical-annexures-assets",
      mapping: {
        sourceRows: ["row-18", "row-2", "row-19"],
        sourceCols: [9],
        targetRows: ["row-29"],
        targetCols: [9]
      }
    }
  ]
};

export default complexCalTable;
