import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import "./App.css";
import "./pages/pages.css";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import TableList from "./pages/TableList";
import CreateTable from "./pages/CreateTable";
import ViewTable from "./pages/ViewTable";
import Mapping from "./pages/mapping";

function App() {
  return (
    <Router>
      {/* Wrap your routes inside Router */}
      <Routes>
        <Route path="/register" element={<Registration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/tables" element={<TableList />} />
        <Route path="/create-table" element={<CreateTable />} />
        <Route path="/view-table/:id" element={<ViewTable />} />
        <Route path="/mapping" element={<Mapping />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
