import React, { useState } from "react";
import axios from "axios";
import { IoMdCloseCircleOutline } from "react-icons/io";

const CreateTable = ({ onClose, handleTableCreated }) => {
  const [tableName, setTableName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateTable = () => {
    if (!tableName) {
      alert("Please enter a table name.");
      return;
    }

    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    // Get token from localStorage
    const token = localStorage.getItem("token");

    axios
      .post(
        "https://vertical.lblfm.com/api/tabledata",
        {
          baseTableName: tableName
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        setIsLoading(false);
        setSuccessMessage("Table created successfully!");
        setTimeout(() => {
          if (handleTableCreated) {
            handleTableCreated(response.data);
          }
          onClose();
        }, 1000); // 1-second delay
      })
      .catch((error) => {
        setIsLoading(false);
        const errorMsg =
          error.response?.data?.error || "An error occurred. Please try again.";
        setErrorMessage(errorMsg);
      });
  };

  return (
    <div className="create-table-container">
      <div className="close-button" onClick={onClose}>
        <IoMdCloseCircleOutline />
      </div>
      <h2>Create Table</h2>
      <label>Enter The Table Name:</label>
      <input
        type="text"
        placeholder="Enter table name"
        value={tableName}
        onChange={(e) => setTableName(e.target.value)}
        className="table-name-input"
      />
      <button
        className="create-table-button"
        onClick={handleCreateTable}
        disabled={isLoading}
      >
        {isLoading ? "Please wait, generating..." : "Create New Tables"}
      </button>
      {isLoading && <div className="loading-message">Generating...</div>}
      {successMessage && !errorMessage && (
        <div className="success-message">{successMessage}</div>
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default CreateTable;
