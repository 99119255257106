import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";

import { MdDelete } from "react-icons/md";

const AddMappingPage = () => {
  const [sourceType, setSourceType] = useState("horizontal");
  const [targetType, setTargetType] = useState("horizontal");
  const [source, setSource] = useState("");
  const [target, setTarget] = useState("");
  const [mappings, setMappings] = useState([
    {
      sourceRows: [""],
      sourceCols: [""],
      targetRows: [""],
      targetCols: [""]
    }
  ]);

  const [existingMappings, setExistingMappings] = useState([]);

  // Available horizontal and vertical table names
  const horizontalNames = [
    "horizontal-profit-loss",
    "horizontal-Balance-Sheet",
    "horizontal-BS-1_3",
    "horizontal-Bs-4_6",
    "horizontal-BS-7_10",
    "horizontal-BS-11",
    "horizontal-BS-12_18",
    "horizontal-Bs-1_3_A",
    "horizontal-Note-AA",
    "horizontal-PL-19_20"
  ];

  const verticalNames = [
    "vertical-profit-loss",
    "vertical-Balance-Sheet",
    "vertical-BS-1_3",
    "vertical-annexures-cap-liab",
    "vertical-annexures-assets"
  ];

  // Fetch existing mappings
  useEffect(() => {
    axios
      .get("https://vertical.lblfm.com/api/mappings")
      .then((response) => {
        setExistingMappings(response.data);
      })
      .catch((error) => {
        console.error("Error fetching mappings:", error.message);
      });
  }, []);

  const handleSourceTypeChange = (e) => setSourceType(e.target.value);
  const handleTargetTypeChange = (e) => setTargetType(e.target.value);

  const handleSourceChange = (e) => {
    setSource(e.target.value);
    // Reset target table selection when source table changes
    setTarget("");
  };

  const handleTargetChange = (e) => setTarget(e.target.value);

  const handleMappingChange = (index, field, value) => {
    const updatedMappings = [...mappings];
    updatedMappings[index][field] = value;
    setMappings(updatedMappings);
  };

  const handleAddMapping = () => {
    setMappings([
      ...mappings,
      {
        sourceRows: [""],
        sourceCols: [""],
        targetRows: [""],
        targetCols: [""]
      }
    ]);
  };

  const handleDeleteMappingItem = async (mappingId) => {
    try {
      await axios.delete(
        `https://vertical.lblfm.com/api/mappings/${source}/${target}/${mappingId}`
      );
      alert("Mapping item deleted successfully!");

      // Update local state by filtering out the deleted mapping item
      setExistingMappings(
        existingMappings.map((mapping) =>
          mapping.source === source && mapping.target === target
            ? {
                ...mapping,
                mappings: mapping.mappings.filter(
                  (item) => item._id !== mappingId
                )
              }
            : mapping
        )
      );
    } catch (error) {
      alert("Error deleting mapping item: " + error.message);
    }
  };

  const handleDeleteMappingByIndex = (index) => {
    const updatedMappings = mappings.filter((_, idx) => idx !== index);
    setMappings(updatedMappings);
  };

  // we can use this to delete the entier mapped data this is bad user exprice to flow to skip this.

  // const handleDeleteMapping = async (source, target) => {
  //   try {
  //     await axios.delete(
  //       `https://vertical.lblfm.com/api/mappings/${source}/${target}`
  //     );
  //     alert("Mapping deleted successfully!");
  //     // Refresh the mappings
  //     setExistingMappings(
  //       existingMappings.filter(
  //         (mapping) => mapping.source !== source || mapping.target !== target
  //       )
  //     );
  //   } catch (error) {
  //     alert("Error deleting mapping: " + error.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mappingData = {
      source,
      target,
      mappings: mappings.map((mapping) => ({
        ...mapping,
        sourceRows: mapping.sourceRows.map((row) => `row-${row}`), // Format source rows
        targetRows: mapping.targetRows.map((row) => `row-${row}`) // Format target rows
      }))
    };

    try {
      await axios.post("https://vertical.lblfm.com/api/mappings", mappingData);
      alert("Mapping added successfully");

      // Clear the form data and remove all mappings
      setSource("");
      setTarget("");
      setMappings([
        {
          sourceRows: [""],
          sourceCols: [""],
          targetRows: [""],
          targetCols: [""]
        }
      ]);
      setSourceType("horizontal");
      setTargetType("horizontal");
    } catch (error) {
      alert("Error adding mapping: " + error.message);
    }
  };

  // Filter existing mappings based on selected source and target
  const filteredMappings = existingMappings.filter(
    (mapping) => mapping.source === source && mapping.target === target
  );

  const containerStyle = {
    height: "100vh",
    overflow: "scroll"
  };

  const fixedHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1,
    paddingBottom: "10px"
  };

  const formControlStyle = {
    marginBottom: "10px"
  };

  const tableStyle = {
    margin: "0 auto", // Center the table horizontally
    borderCollapse: "collapse", // Remove spaces between table cells
    width: "80%", // Adjust width as per need
    textAlign: "center", // Center align text in table
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" // Optional: Adds subtle shadow
  };

  const thStyle = {
    backgroundColor: "#4CAF50", // Header background color
    color: "white", // Header text color
    padding: "10px", // Spacing inside cells
    border: "1px solid #ddd", // Optional: Border for cells
    fontWeight: "bold", // Bold font
    textAlign: "center" // Center align text in header
  };

  const tdStyle = {
    padding: "10px", // Spacing inside cells
    border: "1px solid #ddd", // Optional: Border for cells
    textAlign: "center" // Center align text
  };

  return (
    <div maxWidth="lg" className="edit-container-main" style={containerStyle}>
      <Box style={fixedHeaderStyle}>
        <Typography variant="h4" gutterBottom className="edit-title">
          Manage Mappings
        </Typography>

        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth style={formControlStyle}>
              <InputLabel>Source Type</InputLabel>
              <Select
                value={sourceType}
                onChange={handleSourceTypeChange}
                label="Source Type"
              >
                <MenuItem value="horizontal">Horizontal</MenuItem>
                <MenuItem value="vertical">Vertical</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth style={formControlStyle}>
              <InputLabel>Target Type</InputLabel>
              <Select
                value={targetType}
                onChange={handleTargetTypeChange}
                label="Target Type"
              >
                <MenuItem value="horizontal">Horizontal</MenuItem>
                <MenuItem value="vertical">Vertical</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth style={formControlStyle}>
              <InputLabel>Source Table</InputLabel>
              <Select
                value={source}
                onChange={handleSourceChange}
                label="Source Table"
                required
              >
                {(sourceType === "horizontal"
                  ? horizontalNames
                  : verticalNames
                ).map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth style={formControlStyle}>
              <InputLabel>Target Table</InputLabel>
              <Select
                value={target}
                onChange={handleTargetChange}
                label="Target Table"
                required
              >
                {(targetType === "horizontal"
                  ? horizontalNames
                  : verticalNames
                ).map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={4}>
        {/* Left Section: Existing Mappings */}
        <Grid item xs={12} md={6} className="edit-left containerMapping">
          <Typography variant="h6" className="edit-subtitle">
            Existing Mappings
          </Typography>
          <Box className="edit-mapping-list">
            {/* Table for displaying mappings */}
            {filteredMappings.length > 0 ? (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={thStyle}>#</th>
                    <th style={thStyle}>Source Rows</th>
                    <th style={thStyle}>Source Columns</th>
                    <th style={thStyle}>Target Rows</th>
                    <th style={thStyle}>Target Columns</th>
                    <th style={thStyle}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMappings.map((mapping, mappingIndex) =>
                    mapping.mappings.map((map, mapIndex) => (
                      <tr key={map._id}>
                        <td style={tdStyle}>
                          {mappingIndex + 1}.{mapIndex + 1}
                        </td>
                        <td style={tdStyle}>{map.sourceRows.join(", ")}</td>
                        <td style={tdStyle}>{map.sourceCols.join(", ")}</td>
                        <td style={tdStyle}>{map.targetRows.join(", ")}</td>
                        <td style={tdStyle}>{map.targetCols.join(", ")}</td>
                        <td style={tdStyle}>
                          <div
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => handleDeleteMappingItem(map._id)}
                          >
                            <MdDelete />
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            ) : (
              <Typography variant="body1" className="edit-no-mappings">
                No mappings found for selected source and target.
              </Typography>
            )}
          </Box>
        </Grid>

        {/* Right Section: Add New Mapping */}
        <Grid item xs={12} md={6} className="edit-right containerMapping">
          <form onSubmit={handleSubmit} className="edit-form">
            <Typography variant="h6" className="edit-subtitle">
              Add New Mapping
            </Typography>
            {/* Dynamic Mapping Section */}
            {mappings.map((mapping, index) => (
              <Box key={index} mt={2}>
                <Card className="edit-mapping-section">
                  <CardContent>
                    <Typography variant="h6">Mapping {index + 1}</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Source Rows"
                          fullWidth
                          value={mapping.sourceRows.join(", ")}
                          onChange={(e) =>
                            handleMappingChange(
                              index,
                              "sourceRows",
                              e.target.value.split(", ").map(Number)
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Source Columns"
                          fullWidth
                          value={mapping.sourceCols.join(", ")}
                          onChange={(e) =>
                            handleMappingChange(
                              index,
                              "sourceCols",
                              e.target.value.split(", ").map(Number)
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Target Rows"
                          fullWidth
                          value={mapping.targetRows.join(", ")}
                          onChange={(e) =>
                            handleMappingChange(
                              index,
                              "targetRows",
                              e.target.value.split(", ").map(Number)
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Target Columns"
                          fullWidth
                          value={mapping.targetCols.join(", ")}
                          onChange={(e) =>
                            handleMappingChange(
                              index,
                              "targetCols",
                              e.target.value.split(", ").map(Number)
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    <Box mt={2}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteMappingByIndex(index)}
                      >
                        Remove Mapping
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
            <Box mt={2}>
              <Button variant="outlined" onClick={handleAddMapping}>
                Add Mapping
              </Button>
            </Box>
            <Box mt={2}>
              <Button variant="contained" color="primary" type="submit">
                Save Mappings
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddMappingPage;
