import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from "react";
import axios from "axios";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltH, faArrowsAltV } from "@fortawesome/free-solid-svg-icons";
import { FaUndo, FaPlus, FaMinus, FaTrashAlt, FaSave } from "react-icons/fa";
import singleTableCopy from "../CelCopyData/singleTableCopy";
import calTableCopy from "../CelCopyData/calTableCopy";
import Calculator from "../components/Calculator";
import complexCalTable from "../CelCopyData/complexCalTable";
import multiCalculationTable from "../CelCopyData/multiCalculationTable";
import Popup from "../components/Popup";
import * as XLSX from "xlsx";
// import { FaFileDownload, FaFilePdf } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";

const MyTable = () => {
  // Store dropdown selections in localStorage with table ID prefix
  const [dropdownSelections, setDropdownSelections] = useState(() => {
    const savedSelections = localStorage.getItem("dropdownSelections");
    return savedSelections ? JSON.parse(savedSelections) : {};
  });

  const [tableData, setTableData] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedCell, setSelectedCell] = useState({
    rowIndex: 0,
    cellIndex: 0
  });
  const [contextMenu, setContextMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const { id } = useParams();
  const [relatedTables, setRelatedTables] = useState([]);
  const [viewMode, setViewMode] = useState("horizontal");
  const [showDropdown, setShowDropdown] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [baseTableName, setBaseTableName] = useState(null);
  const [lastCopiedValue, setLastCopiedValue] = useState(null); // Track the last copied value
  const [showRowNumbers, setShowRowNumbers] = useState(false); // State to toggle row numbers

  const handleMoveToFolderList = () => {
    navigate("/tables");
  };

  const handleSave = () => {
    setIsSaving(true);
    // Save entire table data when Save button is clicked
    axios
      .put(`https://vertical.lblfm.com/api/tabledata/${id}`, tableData)
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          setIsSaving(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error saving table data:", error);
        setIsSaving(false);
      });
  };

  const handleDownloadPDF = () => {
    if (!tableData || !tableData.rows) return;

    const doc = new jsPDF("l", "mm", "a4");

    // Prepare table content without header row
    const tableContent = tableData.rows.slice(1).map((row) => {
      return row.cells.map((cell) => cell.content || "");
    });

    // Calculate optimal column widths based on content
    const maxColumns = Math.max(...tableContent.map((row) => row.length));
    const pageWidth = doc.internal.pageSize.width - 20; // Leave 10mm margin on each side
    const minColWidth = 20; // Minimum column width in mm

    // Calculate column widths based on content
    const columnWidths = Array(maxColumns)
      .fill(0)
      .map((_, colIndex) => {
        // Get max content length for this column
        const maxContentLength = Math.max(
          ...tableContent.map((row) => String(row[colIndex] || "").length)
        );
        // Convert length to mm (approximate - assuming 2mm per character)
        return Math.max(minColWidth, Math.min(maxContentLength * 2, 40)); // Cap at 40mm
      });

    // Adjust widths to fit page
    const totalWidth = columnWidths.reduce((sum, width) => sum + width, 0);
    if (totalWidth > pageWidth) {
      const scale = pageWidth / totalWidth;
      columnWidths.forEach((_, i) => {
        columnWidths[i] *= scale;
      });
    }

    // Calculate available height for table content
    const pageHeight = doc.internal.pageSize.height;
    const headerHeight = 15; // Height reserved for header
    const footerHeight = 15; // Height reserved for footer
    const availableHeight = pageHeight - headerHeight - footerHeight;

    // Calculate rows that will fit on each page
    const rowHeight = 8; // Approximate height per row in mm
    const maxRowsPerPage = Math.floor(availableHeight / rowHeight);

    // Split content into pages, ensuring no gaps between rows
    const pages = [];
    let currentPage = [];
    let currentHeight = 0;

    tableContent.forEach((row) => {
      if (
        currentHeight + rowHeight > availableHeight &&
        currentPage.length > 0
      ) {
        pages.push(currentPage);
        currentPage = [row];
        currentHeight = rowHeight;
      } else {
        currentPage.push(row);
        currentHeight += rowHeight;
      }
    });

    if (currentPage.length > 0) {
      pages.push(currentPage);
    }

    pages.forEach((pageContent, pageIndex) => {
      if (pageIndex > 0) {
        doc.addPage();
      }

      // Add minimal header with table name and date
      const timestamp = new Date().toLocaleDateString();
      doc.setFontSize(12);
      doc.setFont(undefined, "bold");
      doc.text(tableData.name || "Table Data", 15, 10);
      doc.text(timestamp, doc.internal.pageSize.width - 30, 10);

      // Generate table
      doc.autoTable({
        body: pageContent,
        startY: headerHeight,
        styles: {
          fontSize: 10,
          fontStyle: "bold",
          cellPadding: 3,
          lineColor: [200, 200, 200],
          lineWidth: 0.1,
          overflow: "linebreak",
          cellWidth: "wrap",
          minCellHeight: 8,
          halign: "left"
        },
        columnStyles: columnWidths.reduce((acc, width, index) => {
          acc[index] = {
            cellWidth: width,
            halign: "left",
            valign: "middle"
          };
          return acc;
        }, {}),
        theme: "grid",
        margin: {
          top: headerHeight,
          left: 10,
          right: 10,
          bottom: footerHeight
        },
        didDrawPage: (data) => {
          const pageSize = doc.internal.pageSize;
          doc.setFontSize(10);
          doc.setTextColor(100, 100, 100);
          doc.text(
            `Page ${pageIndex + 1} of ${pages.length}`,
            pageSize.width / 2,
            pageSize.height - 10,
            { align: "center" }
          );
        },
        didDrawCell: (data) => {
          if (data.cell.text.length > 0) {
            const fontSize = data.cell.styles.fontSize;
            const cellWidth = data.cell.styles.cellWidth;
            const textWidth =
              doc.getStringUnitWidth(data.cell.text[0]) * fontSize;

            if (textWidth > cellWidth) {
              data.cell.styles.fontSize = Math.floor(
                fontSize * (cellWidth / textWidth)
              );
            }
          }
        }
      });
    });

    // Save with timestamp
    const fileName = `${tableData.name || "export"}_${
      new Date().toISOString().split("T")[0]
    }.pdf`;
    doc.save(fileName);
  };

  const handleDownload = () => {
    if (!tableData || !tableData.rows) return;

    // Convert table data to Excel format
    const ws = XLSX.utils.aoa_to_sheet(
      tableData.rows.map((row) => row.cells.map((cell) => cell.content || ""))
    );

    // Add styling to the worksheet
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let R = range.s.r; R <= range.e.r; R++) {
      for (let C = range.s.c; C <= range.e.c; C++) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);

        if (!ws[cell_ref]) continue;

        // Add cell styling
        ws[cell_ref].s = {
          font: {
            name: "Arial",
            sz: 12,
            color: { rgb: "000000" }
          },
          alignment: {
            vertical: "center",
            horizontal: "left",
            wrapText: true // Enable text wrapping
          },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } }
          }
        };

        // Style header row
        if (R === 0) {
          ws[cell_ref].s.fill = {
            fgColor: { rgb: "FFB402" },
            type: "pattern",
            patternType: "solid"
          };
          ws[cell_ref].s.font.bold = true;
        }
      }
    }

    // Set dynamic column widths based on content
    const colWidths = [];
    for (let C = range.s.c; C <= range.e.c; C++) {
      let maxWidth = 10; // Minimum width
      for (let R = range.s.r; R <= range.e.r; R++) {
        const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
        if (ws[cell_ref] && ws[cell_ref].v) {
          const contentLength = String(ws[cell_ref].v).length;
          maxWidth = Math.max(maxWidth, Math.min(contentLength, 40)); // Cap at 40 characters
        }
      }
      colWidths.push({ wch: maxWidth });
    }
    ws["!cols"] = colWidths;

    // Create workbook and append worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Table Data");

    // Generate filename from table name with timestamp
    const timestamp = new Date().toISOString().split("T")[0];
    const fileName = `${tableData.name || "table-data"}_${timestamp}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const saveTableData = useCallback(
    (updatedData = tableData, tableId = id) => {
      if (!updatedData) return;
      // Only save entire table data when explicitly called (e.g. from Save button)
      axios
        .put(`https://vertical.lblfm.com/api/tablecell/${tableId}`, updatedData)
        .then((response) => console.log("Table data saved successfully"))
        .catch((error) => console.error("Error saving table data:", error));
    },
    [id, tableData]
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, [navigate]);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLoadingError(null);

      try {
        const response = await axios.get(
          `https://vertical.lblfm.com/api/tabledata/${id}`
        );
        setTableData(response.data);

        // Load saved dropdown selections for this table
        const savedSelections = localStorage.getItem(
          `dropdownSelections_${id}`
        );
        if (savedSelections) {
          setDropdownSelections(JSON.parse(savedSelections));
        }
      } catch (error) {
        console.error("API Error:", error);
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            setLoadingError("Unauthorized access. Please log in again.");
            navigate("/login");
          } else {
            setLoadingError(
              `API Error: ${error.response.data.message || "An error occurred"}`
            );
          }
        } else {
          setLoadingError("Error occurred while setting up the request.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, navigate]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (tableData && tableData.rows) {
        const { key } = event;
        let { rowIndex, cellIndex } = selectedCell;
        const maxRowIndex = tableData.rows.length - 1;
        const maxCellIndex = tableData.rows[rowIndex]?.cells.length - 1 || 0;

        switch (key) {
          case "ArrowUp":
            rowIndex = rowIndex > 0 ? rowIndex - 1 : rowIndex;
            break;
          case "ArrowDown":
            rowIndex = rowIndex < maxRowIndex ? rowIndex + 1 : rowIndex;
            break;
          case "ArrowLeft":
            cellIndex = cellIndex > 0 ? cellIndex - 1 : cellIndex;
            break;
          case "ArrowRight":
            cellIndex = cellIndex < maxCellIndex ? cellIndex + 1 : cellIndex;
            break;
          case "Enter":
            rowIndex = rowIndex < maxRowIndex ? rowIndex + 1 : rowIndex;
            break;
          default:
            return;
        }

        setSelectedCell({ rowIndex, cellIndex });
        setTimeout(() => {
          const inputElement = document.querySelector(
            `[data-row="${rowIndex}"][data-cell="${cellIndex}"] input`
          );
          if (inputElement) {
            inputElement.focus();
          }
        }, 0);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedCell, tableData]);

  const formatValue = (value) => {
    if (typeof value === "string" && value.trim() !== "") {
      // Remove commas and parse the numeric value
      const numericValue = parseFloat(value.replace(/,/g, ""));
      if (!isNaN(numericValue)) {
        // Format the number with locale-specific separators
        return numericValue.toLocaleString("en-US");
      }
    } else if (typeof value === "number") {
      // Format directly if the value is already a number
      return value.toLocaleString("en-US");
    }
    // Return the original value if it's not a valid number
    return value;
  };

  const handleInputChange = (rowIndex, cellIndex, value) => {
    if (!tableData || !tableData.rows[rowIndex]) return;
    const currentCellContent =
      tableData.rows[rowIndex].cells[cellIndex].content;

    const startsWithNumbers = /^\d{2}/.test(value);
    if (startsWithNumbers && value.match(/[A-Za-z]/)) {
      return;
    }

    const formattedValue = formatValue(value);

    // Only update if value actually changed
    if (currentCellContent !== formattedValue) {
      // Update single cell using new API endpoint
      const rowId = tableData.rows[rowIndex].id;
      const cellId = tableData.rows[rowIndex].cells[cellIndex]._id;

      axios
        .put(`https://vertical.lblfm.com/api/tablecell/${id}`, {
          rowId,
          cellId,
          content: formattedValue
        })
        .then(() => {
          // Update local state after successful API call
          setTableData((prevTableData) => {
            const newRows = [...prevTableData.rows];
            newRows[rowIndex].cells[cellIndex] = {
              ...newRows[rowIndex].cells[cellIndex],
              content: formattedValue,
              isUserProvided: true
            };
            return { ...prevTableData, rows: newRows };
          });

          if (formattedValue !== lastCopiedValue) {
            setLastCopiedValue(formattedValue);
          }
        })
        .catch((error) => {
          console.error("Error updating cell:", error);
        });
    }
  };

  const handleClick = (rowIndex, cellIndex) => {
    setSelectedCell({ rowIndex, cellIndex });
    setTimeout(() => {
      document
        .querySelector(
          `[data-row="${rowIndex}"][data-cell="${cellIndex}"] input`
        )
        ?.focus();
    }, 0);
  };

  const createCell = (tag, cellData, rowIndex, cellIndex) => {
    if (!cellData) return null; // Handle null or undefined cellData
    const CellTag = tag;
    const isSelected =
      selectedCell.rowIndex === rowIndex &&
      selectedCell.cellIndex === cellIndex;
    const isEditable =
      (cellData.content === "0" ||
        cellData.content === "" ||
        cellData.isUserProvided) &&
      !cellData.isCopied; // Ensure copied cells are not editable

    return isEditable ? (
      <CellTag
        key={`cell-${rowIndex}-${cellIndex}`}
        colSpan={cellData.colspan}
        rowSpan={cellData.rowspan}
        className={`${cellData.class} ${isSelected ? "selected-cell" : ""}`}
        data-row={rowIndex}
        data-cell={cellIndex}
        onClick={() => handleClick(rowIndex, cellIndex)}
      >
        <input
          type="text"
          placeholder={cellData.content === "0" ? "0" : ""}
          value={cellData.content === "0" ? "" : cellData.content}
          onFocus={(e) => e.target.select()}
          onChange={(e) =>
            handleInputChange(rowIndex, cellIndex, e.target.value)
          }
          autoFocus={isSelected}
          tabIndex={0}
          readOnly={cellData.isCopied} // Make the cell read-only if it is copied
        />
      </CellTag>
    ) : (
      <CellTag
        key={`cell-${rowIndex}-${cellIndex}`}
        colSpan={cellData.colspan}
        rowSpan={cellData.rowspan}
        className={`${cellData.class} ${isSelected ? "selected-cell" : ""}`}
        dangerouslySetInnerHTML={
          cellData.content ? { __html: cellData.content } : null
        }
        data-row={rowIndex}
        data-cell={cellIndex}
        onClick={() => handleClick(rowIndex, cellIndex)}
        tabIndex={0}
      />
    );
  };

  // hear the drop down will start
  const handleDropdownChange = (rowIndex, cellIndex, value) => {
    // Update state with table ID prefix
    const newSelections = {
      ...dropdownSelections,
      [`${rowIndex}-${cellIndex}`]: value
    };
    setDropdownSelections(newSelections);

    // Save selections with table ID prefix
    localStorage.setItem(
      `dropdownSelections_${id}`,
      JSON.stringify(newSelections)
    );

    // Update table data for display in Row-2 only if user explicitly changes selection
    setTableData((prevTableData) => {
      const newRows = [...prevTableData.rows];
      newRows[rowIndex + 1].cells[cellIndex] = {
        ...newRows[rowIndex + 1].cells[cellIndex],
        content: value
      };
      return { ...prevTableData, rows: newRows };
    });
  };

  // Example for creating cells with dropdowns
  const createDropdownCell = (cellData, rowIndex, cellIndex) => {
    if (!cellData) return null; // Handle null or undefined cellData
    return (
      <td key={`cell-${rowIndex}-${cellIndex}`} className={cellData.class}>
        <select
          value={
            dropdownSelections[`${rowIndex}-${cellIndex}`] || cellData.content
          }
          onChange={(e) =>
            handleDropdownChange(rowIndex, cellIndex, e.target.value)
          }
        >
          {cellData.options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      </td>
    );
  };
  const createRow = (rowData, rowIndex) => {
    return (
      <tr
        key={`row-${rowIndex}`}
        className={`text-center bordered-row ${
          contextMenu?.rowIndex === rowIndex ? "highlighted-row" : ""
        }`}
        onContextMenu={(e) => handleContextMenu(e, rowIndex)}
      >
        {rowData.cells.map((cellData, cellIndex) => (
          <React.Fragment key={`fragment-${rowIndex}-${cellIndex}`}>
            {cellData && cellData.type === "dropdown"
              ? createDropdownCell(cellData, rowIndex, cellIndex)
              : createCell("td", cellData, rowIndex, cellIndex)}
          </React.Fragment>
        ))}
        {showRowNumbers && <td className="row-number">{rowData.id}</td>}
        {/* Show row ID instead of index */}
      </tr>
    );
  };

  const handleContextMenu = (event, rowIndex) => {
    event.preventDefault();
    const cell = event.target.closest("td, th");
    if (!cell) return;
    const rect = cell.getBoundingClientRect();

    setContextMenu({
      rowIndex,
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX
    });
  };
  const handleContextMenuAction = (action) => {
    const { rowIndex } = contextMenu;
    const newRows = [...tableData.rows];
    setUndoStack((prevUndoStack) => [
      ...prevUndoStack,
      JSON.stringify(tableData.rows)
    ]);
    let dataChanged = false;

    // Simplified helper function to generate sequential row IDs
    const generateNewRowId = (parentId) => {
      const existingIds = newRows.map((row) => row.id);
      let counter = 1;
      let newId = `${parentId}.${counter}`;

      while (existingIds.includes(newId)) {
        counter++;
        newId = `${parentId}.${counter}`;
      }

      return newId;
    };

    if (action === "addAbove") {
      const baseId = newRows[rowIndex].id.split(".")[0]; // Get base ID like "row-89"
      const newRowId = generateNewRowId(baseId);
      newRows.splice(rowIndex, 0, {
        cells: newRows[rowIndex].cells.map((cell) => ({ content: "" })),
        id: newRowId
      });
      dataChanged = true;
    } else if (action === "addBelow") {
      const baseId = newRows[rowIndex].id.split(".")[0];
      const newRowId = generateNewRowId(baseId);
      newRows.splice(rowIndex + 1, 0, {
        cells: newRows[rowIndex].cells.map((cell) => ({ content: "" })),
        id: newRowId
      });
      dataChanged = true;
    } else if (action === "removeAbove") {
      if (rowIndex > 0) {
        newRows.splice(rowIndex - 1, 1);
        dataChanged = true;
      }
    } else if (action === "removeBelow") {
      if (rowIndex < newRows.length - 1) {
        newRows.splice(rowIndex + 1, 1);
        dataChanged = true;
      }
    }

    if (dataChanged) {
      setTableData({ ...tableData, rows: newRows });
      saveTableData();
    }
    setContextMenu(null);
  };

  const handleUndo = () => {
    setUndoStack((prevUndoStack) => {
      if (prevUndoStack.length > 0) {
        const lastState = prevUndoStack[prevUndoStack.length - 1];
        const newRows = JSON.parse(lastState);

        setTableData((prevTableData) => ({
          ...prevTableData,
          rows: newRows
        }));

        // Only save if rows actually changed
        if (JSON.stringify(tableData.rows) !== lastState) {
          saveTableData();
        }

        return prevUndoStack.slice(0, -1);
      }
      return prevUndoStack;
    });
  };
  const handleAddRow = (rowIndex) => {
    setUndoStack((prevUndoStack) => [
      ...prevUndoStack,
      JSON.stringify(tableData.rows)
    ]);

    setTableData((prevTableData) => {
      const newRows = [...prevTableData.rows];
      const baseId = newRows[rowIndex].id.split(".")[0];

      // Generate sequential ID
      const existingIds = newRows.map((row) => row.id);
      let counter = 1;
      let newId = `${baseId}.${counter}`;

      while (existingIds.includes(newId)) {
        counter++;
        newId = `${baseId}.${counter}`;
      }

      newRows.splice(rowIndex + 1, 0, {
        cells: newRows[rowIndex].cells.map((cell) => ({
          content: ""
        })),
        id: newId
      });

      setTimeout(() => saveTableData(), 0);
      return { ...prevTableData, rows: newRows };
    });
  };

  const handleRemoveRow = (rowIndex) => {
    setUndoStack((prevUndoStack) => [
      ...prevUndoStack,
      JSON.stringify(tableData.rows)
    ]);

    setTableData((prevTableData) => {
      const newRows = prevTableData.rows.filter(
        (_, index) => index !== rowIndex
      );

      // Save after removing row
      setTimeout(() => saveTableData(), 0);

      return { ...prevTableData, rows: newRows };
    });
  };

  const removeContextMenu = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    const fetchTable = async () => {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      try {
        // Fetch table by ID
        const tableResponse = await axios.get(
          `https://vertical.lblfm.com/api/tabledata/${id}`
        );
        setBaseTableName(tableResponse.data.baseTableName);

        // Fetch related tables using the new API
        const relatedResponse = await axios.get(
          `https://vertical.lblfm.com/api/tables/metadata`
        );

        // Filter related tables based on baseTableName
        const related = relatedResponse.data.find(
          (group) => group.baseTableName === tableResponse.data.baseTableName
        );

        if (related) {
          setRelatedTables(related.tables); // Ensure this updates the state
        } else {
          setRelatedTables([]);
          console.warn(
            "No related tables found for baseTableName:",
            tableResponse.data.baseTableName
          );
        }
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchTable();
  }, [id]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        try {
          const response = await axios.get(
            "https://vertical.lblfm.com/api/userdetails"
          );
          setUserDetails(response.data);
        } catch (error) {
          console.error("Error fetching user details:", error);
          navigate("/login");
        } finally {
          setLoadingUser(false);
        }
      } else {
        navigate("/login");
      }
    };
    fetchUserDetails();
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    const savedViewMode = localStorage.getItem("viewMode");
    if (savedViewMode) {
      setViewMode(savedViewMode);
    }
  }, []);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    localStorage.setItem("viewMode", mode);
  };

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  // const filteredTables = relatedTables.filter((t) => {
  //   const name = t.name ? t.name.toLowerCase() : "";
  //   const isViewModeMatch = name.includes(viewMode);
  //   const isSpecificTable = name !== `create table-${viewMode}-`;
  //   return isViewModeMatch && isSpecificTable;
  // });

  const getDisplayName = (name) => {
    const parts = name ? name.split("-") : [];
    return parts.slice(-2).join(" ");
  };

  const getTableId = (name) => {
    const parts = name ? name.split("-") : [];
    return parts.slice(1).join("-");
  };

  const toggleRowNumbers = () => {
    setShowRowNumbers((prev) => !prev);
  };
  // perform calculation ------------------------------------------------------------------------------------------------
  const performCalculation = useCallback(() => {
    if (tableData && tableData.name) {
      let newTableData = { ...tableData, rows: [...tableData.rows] };
      let shouldUpdate = false;

      const getNestedRowValues = (rowId, colIndex) => {
        const row = newTableData.rows.find((r) => r.id === rowId);
        if (!row) return 0;

        // Initialize sum with the main row value
        let sum = parseFloat(
          row.cells?.[colIndex]?.content?.replace(/,/g, "") || "0"
        );

        // Check for nested rows (format: row-XX.Y)
        const baseRowId = rowId.split(".")[0];
        const nestedRows = newTableData.rows.filter(
          (r) => r.id.startsWith(baseRowId + ".") && r.id !== rowId
        );

        // Add values from nested rows
        nestedRows.forEach((nestedRow) => {
          if (!nestedRow.cells) nestedRow.cells = [];
          while (nestedRow.cells.length <= colIndex) {
            nestedRow.cells.push({ content: "0" });
          }
          sum += parseFloat(
            nestedRow.cells[colIndex]?.content?.replace(/,/g, "") || "0"
          );
        });

        return sum;
      };

      calTableCopy.calcutation.forEach((calculation) => {
        const { source, mapping } = calculation;

        if (tableData.name.startsWith(`${baseTableName}-${source}`)) {
          const { sourceRows, sourceCols, targetRows, targetCols, operation } =
            mapping;

          if (!sourceRows || !sourceCols || !targetRows || !targetCols) return;

          const values = sourceRows.map((rowId) =>
            sourceCols.reduce(
              (sum, colIndex) => sum + getNestedRowValues(rowId, colIndex),
              0
            )
          );

          let result = 0;
          switch (operation) {
            case "sum":
              result = values.reduce((acc, curr) => acc + curr, 0);
              break;
            case "mul":
              result = values.reduce((acc, curr) => acc * curr, 1);
              break;
            case "subtract":
              result = values.reduce((acc, curr) => acc - curr);
              break;
            case "divide":
              result = values.reduce((acc, curr) => acc / curr);
              break;
            case "percent":
              result = (values[0] / values[1]) * 100;
              break;
            default:
              return;
          }

          targetRows.forEach((targetRowId) => {
            const targetRow = newTableData.rows.find(
              (r) => r.id === targetRowId
            );
            if (targetRow) {
              if (!targetRow.cells) targetRow.cells = [];
              while (targetRow.cells.length <= Math.max(...targetCols)) {
                targetRow.cells.push({ content: "0" });
              }

              targetCols.forEach((targetColIndex) => {
                const oldContent = targetRow.cells[targetColIndex]?.content;
                const newContent = result.toString();

                if (oldContent !== newContent) {
                  targetRow.cells[targetColIndex] = { content: newContent };
                  shouldUpdate = true;
                }
              });
            }
          });
        }
      });

      if (shouldUpdate) {
        setTableData(newTableData);
        saveTableData();
      }
    }
  }, [tableData, baseTableName, saveTableData]);
  // from hear the multi table calculation will start ------------------------------------------------------------------------------------------------
  const multiCalculation = useCallback(() => {
    if (tableData && tableData.name) {
      let newTableData = { ...tableData, rows: [...tableData.rows] };
      let shouldUpdate = false;

      multiCalculationTable.calculations.forEach((calculation) => {
        const { source, mapping } = calculation;

        if (tableData.name.startsWith(`${baseTableName}-${source}`)) {
          const { steps, targetRows, targetCols } = mapping;

          if (!steps || !targetRows || !targetCols) return;

          // Get all related rows (main row and sub-rows)
          const getAllRelatedRows = (rowId) => {
            const baseId = rowId.split(".")[0];
            return newTableData.rows.filter(
              (row) => row.id === baseId || row.id.startsWith(`${baseId}.`)
            );
          };

          // Check if this is a same-row calculation
          const isSameRowCalculation = targetRows.every((targetRow) =>
            steps.every((step) =>
              step.sourceRows.every((sourceRow) => sourceRow === targetRow)
            )
          );

          // Process each target row independently
          targetRows.forEach((targetRowId) => {
            const relatedRows = getAllRelatedRows(targetRowId);

            relatedRows.forEach((targetRow) => {
              const currentRowBaseId = targetRow.id.split(".")[0];

              // For same-row calculations, only process if source and target are the same row
              if (isSameRowCalculation) {
                if (currentRowBaseId === targetRowId) {
                  let result = 0;

                  steps.forEach((step) => {
                    const { operation, sourceCols } = step;
                    let stepTotal = 0;

                    // Use only current row's values
                    if (!targetRow.cells) targetRow.cells = [];
                    while (targetRow.cells.length <= Math.max(...sourceCols)) {
                      targetRow.cells.push({ content: "0" });
                    }

                    const rowValues = sourceCols.map((colIndex) =>
                      parseFloat(
                        targetRow.cells[colIndex]?.content?.replace(/,/g, "") ||
                          "0"
                      )
                    );

                    stepTotal = rowValues.reduce((acc, val) => acc + val, 0);

                    // Apply operation
                    if (operation === "sum") {
                      result += stepTotal;
                    } else if (operation === "subtract") {
                      result -= stepTotal;
                    } else if (operation === "mul") {
                      result = result === 0 ? stepTotal : result * stepTotal;
                    } else if (operation === "divide") {
                      if (stepTotal !== 0) {
                        result = result === 0 ? stepTotal : result / stepTotal;
                      }
                    }
                  });

                  // Update target columns for same-row calculation
                  targetCols.forEach((targetColIndex) => {
                    if (!targetRow.cells[targetColIndex]) {
                      targetRow.cells[targetColIndex] = { content: "0" };
                    }
                    const oldContent = targetRow.cells[targetColIndex].content;
                    const newContent = result.toString();

                    if (oldContent !== newContent) {
                      targetRow.cells[targetColIndex] = { content: newContent };
                      shouldUpdate = true;
                    }
                  });
                }
              }
              // For multi-row calculations
              else if (currentRowBaseId === targetRowId.split(".")[0]) {
                let result = 0;

                steps.forEach((step) => {
                  const { operation, sourceRows, sourceCols } = step;
                  let stepTotal = 0;

                  sourceRows.forEach((sourceRowId) => {
                    const sourceRelatedRows = getAllRelatedRows(sourceRowId);

                    sourceRelatedRows.forEach((sourceRow) => {
                      if (!sourceRow.cells) sourceRow.cells = [];
                      while (
                        sourceRow.cells.length <= Math.max(...sourceCols)
                      ) {
                        sourceRow.cells.push({ content: "0" });
                      }

                      const rowValues = sourceCols.map((colIndex) =>
                        parseFloat(
                          sourceRow.cells[colIndex]?.content?.replace(
                            /,/g,
                            ""
                          ) || "0"
                        )
                      );

                      stepTotal += rowValues.reduce((acc, val) => acc + val, 0);
                    });
                  });

                  if (operation === "sum") {
                    result += stepTotal;
                  } else if (operation === "subtract") {
                    result -= stepTotal;
                  } else if (operation === "mul") {
                    result = result === 0 ? stepTotal : result * stepTotal;
                  } else if (operation === "divide") {
                    if (stepTotal !== 0) {
                      result = result === 0 ? stepTotal : result / stepTotal;
                    }
                  }
                });

                // Update target columns for multi-row calculation
                targetCols.forEach((targetColIndex) => {
                  if (!targetRow.cells[targetColIndex]) {
                    targetRow.cells[targetColIndex] = { content: "0" };
                  }
                  const oldContent = targetRow.cells[targetColIndex].content;
                  const newContent = result.toString();

                  if (oldContent !== newContent) {
                    targetRow.cells[targetColIndex] = { content: newContent };
                    shouldUpdate = true;
                  }
                });
              }
            });
          });
        }
      });

      if (shouldUpdate) {
        setTableData(newTableData);
        saveTableData();
      }
    }
  }, [tableData, baseTableName, saveTableData]);

  // end of multi table calculation ------------------------------------------------------------------------------------------------

  //  complex calculation adition ------------------------------------------------------------------------------------------------
  // Utility functions for calculation safety
  // Utility functions for calculation safety
  const safeParseFloat = (value) => {
    if (typeof value === "number") return value;
    const cleaned = String(value).replace(/,/g, "").trim();
    const parsed = parseFloat(cleaned);
    return isNaN(parsed) ? 0 : parsed;
  };

  const toPercentageDecimal = (value) => safeParseFloat(value) / 100;

  const formatNumber = (num) => {
    if (typeof num !== "number" || isNaN(num)) return "0";
    return num.toString();
  };

  // Complex calculation hook
  const useComplexCalculations = ({ tableData, baseTableName, onSave }) => {
    // Memoized function to get cell value
    const getCellValue = useMemo(() => {
      return (rows, rowId, colIndex) => {
        const row = rows.find((r) => r.id === rowId);
        if (!row?.cells?.[colIndex]) return 0;
        return safeParseFloat(row.cells[colIndex].content);
      };
    }, []);

    // Calculate result based on dynamic formula with optional rows
    const calculateResult = useCallback(
      (rows, sourceValues, optionalRows = []) => {
        try {
          const [baseRow, percentageRow, finalRow, ...additionalRows] =
            sourceValues;
          const percentageDecimal = toPercentageDecimal(percentageRow);
          let result = 0;

          // Calculate for all optional rows first (if any)
          additionalRows.forEach((rowValue) => {
            result += rowValue * percentageDecimal;
          });

          // Add base calculation (row6 * row2%)
          result += baseRow * percentageDecimal;

          // Add final part (row8 * (row2/2)%)
          const halfPercentageDecimal = toPercentageDecimal(percentageRow / 2);
          result += finalRow * halfPercentageDecimal;

          return {
            success: true,
            result: result,
            intermediateResults: {
              baseCalculation: baseRow * percentageDecimal,
              optionalCalculations: additionalRows.map(
                (row) => row * percentageDecimal
              ),
              finalCalculation: finalRow * halfPercentageDecimal
            }
          };
        } catch (error) {
          return {
            success: false,
            result: 0
          };
        }
      },
      []
    );

    // Main calculation handler
    const performComplexCalculation = useCallback(() => {
      if (!tableData?.name || !tableData.rows) {
        return;
      }

      // Filter and iterate through all relevant configurations for this table
      const relevantConfigs = complexCalTable.calculation.filter((c) =>
        tableData.name.startsWith(`${baseTableName}-${c.source}`)
      );

      if (relevantConfigs.length === 0) {
        return;
      }

      // Loop through each config and perform calculations
      relevantConfigs.forEach((configEntry) => {
        const config = {
          sourceRowIds: configEntry.mapping.sourceRows,
          sourceColIndex: configEntry.mapping.sourceCols[0],
          targetRowId: configEntry.mapping.targetRows[0],
          targetColIndex: configEntry.mapping.targetCols[0]
        };

        // Get source values
        const sourceValues = config.sourceRowIds.map((rowId) =>
          getCellValue(tableData.rows, rowId, config.sourceColIndex)
        );

        // Perform calculation
        const { success, result } = calculateResult(
          tableData.rows,
          sourceValues
        );

        if (!success) {
          return;
        }

        // Update target cell if value has changed
        const newTableData = { ...tableData, rows: [...tableData.rows] };
        const targetRow = newTableData.rows.find(
          (r) => r.id === config.targetRowId
        );

        if (targetRow) {
          const formattedResult = formatNumber(result);
          const currentValue =
            targetRow.cells?.[config.targetColIndex]?.content;

          if (currentValue !== formattedResult) {
            if (!targetRow.cells) targetRow.cells = [];
            targetRow.cells[config.targetColIndex] = {
              content: formattedResult
            };
            onSave(newTableData);
          }
        }
      });
    }, [tableData, baseTableName, getCellValue, calculateResult, onSave]);

    return {
      performComplexCalculation
    };
  };

  // Copy values from one mapping to another
  const copyValuesFromMapping = useCallback(() => {
    if (tableData && tableData.name) {
      let shouldUpdate = false;

      singleTableCopy.targets.forEach((target) => {
        if (tableData.name.startsWith(`${baseTableName}-${target.source}`)) {
          const mapping = target.mapping;

          mapping.sourceRows.forEach((sourceRow, rowIndex) => {
            const sourceRowData = tableData.rows.find(
              (row) => row.id === sourceRow
            );
            const targetRowData = tableData.rows.find(
              (row) => row.id === mapping.targetRows[rowIndex]
            );

            if (sourceRowData && targetRowData) {
              if (!sourceRowData.cells) sourceRowData.cells = [];
              if (!targetRowData.cells) targetRowData.cells = [];

              while (
                sourceRowData.cells.length <= Math.max(...mapping.sourceCols)
              ) {
                sourceRowData.cells.push({ content: "" });
              }
              while (
                targetRowData.cells.length <= Math.max(...mapping.targetCols)
              ) {
                targetRowData.cells.push({ content: "" });
              }

              mapping.sourceCols.forEach((sourceCol, colIndex) => {
                const targetCol = mapping.targetCols[colIndex];
                const sourceCell = sourceRowData.cells[sourceCol];
                const targetCell = targetRowData.cells[targetCol];

                if (
                  sourceCell &&
                  targetCell &&
                  sourceCell.content !== targetCell.content
                ) {
                  targetCell.content = sourceCell.content;
                  targetCell.isCopied = true; // Mark the cell as copied
                  targetCell.isEditable = false; // Make the cell non-editable
                  shouldUpdate = true;
                }
              });
            }
          });
        }
      });

      if (shouldUpdate) {
        setTableData({ ...tableData });
        saveTableData();
      }
    }
  }, [tableData, baseTableName, saveTableData]); // Added saveTableData to dependencies
  // end of copy values from one mapping to another ------------------------------------------------------------------------------------------------
  // Use the complex calculations hook
  const { performComplexCalculation } = useComplexCalculations({
    tableData,
    baseTableName,
    onSave: saveTableData // Pass the save function
  });

  useEffect(() => {
    if (tableData) {
      multiCalculation();
      performCalculation();
      copyValuesFromMapping();
      performComplexCalculation(); // Call the complex calculation
    }
  }, [
    multiCalculation,
    performCalculation,
    copyValuesFromMapping,
    performComplexCalculation,
    tableData
  ]);

  useEffect(() => {}, [relatedTables]);
  // Determine the active link based on the current location
  const isActiveLink = (path) => location.pathname === path;
  return (
    <div className="table-container" onClick={removeContextMenu}>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#ffb402",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 20px rgba(0,0,0,0.2)",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px"
          }}
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              border: "5px solid #f3f3f3",
              borderTop: "5px solid #ffb402",
              borderRadius: "50%",
              animation: "spin 1s linear infinite"
            }}
          ></div>
          <div style={{ fontWeight: "bold", color: "#fff" }}>Loading...</div>
          <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </div>
      ) : loadingError ? (
        <div className="error">{loadingError}</div>
      ) : (
        <>
          {/* Header Section */}
          <header className="headerMain">
            <div className="header">
              <div className="logo">MyApp</div>
              <nav className={`nav-links ${viewMode}`}>
                {relatedTables && relatedTables.length > 0 ? (
                  // Filter tables based on the viewMode
                  relatedTables
                    .filter((table) =>
                      viewMode === "horizontal"
                        ? table.tableName.includes("horizontal")
                        : table.tableName.includes("vertical")
                    )
                    .map((t) => (
                      <Link
                        key={t.tableId}
                        to={`/view-table/${t.tableId}`}
                        className={`table-link ${
                          isActiveLink(`/view-table/${t.tableId}`)
                            ? "active"
                            : ""
                        }`}
                      >
                        {getDisplayName(t.tableName)}
                      </Link>
                    ))
                ) : (
                  <p>No related tables found.</p>
                )}
              </nav>
              <div className="userButton">
                <button className="open-calculator-btn" onClick={togglePopup}>
                  Open Calculator
                </button>

                <Popup show={showPopup} handleClose={togglePopup}>
                  <Calculator />
                </Popup>

                <button
                  className={`layout-toggle-button ${
                    viewMode === "vertical" ? "vertical" : "horizontal"
                  }`}
                  onClick={() =>
                    handleViewModeChange(
                      viewMode === "horizontal" ? "vertical" : "horizontal"
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      viewMode === "horizontal" ? faArrowsAltV : faArrowsAltH
                    }
                  />
                  {viewMode === "horizontal"
                    ? "Switch to Vertical"
                    : "Switch to Horizontal"}
                </button>
                <div className="user-profile" onClick={toggleDropdown}>
                  <FaUserCircle className="user-icon" />
                  {loadingUser ? (
                    <p>Loading...</p>
                  ) : (
                    <div
                      className={`user-dropdown ${showDropdown ? "show" : ""}`}
                      ref={dropdownRef}
                    >
                      {userDetails ? (
                        <>
                          <p>{userDetails.username}</p>
                          <p>{userDetails.email}</p>
                          <button onClick={handleLogout}>Logout</button>
                        </>
                      ) : (
                        <p>No user details</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="headb">
              <h2>
                Editing Table Name: {tableData ? tableData.name : "Loading..."}
              </h2>
              <div className="d-flex justify-content-between mb-2 topbtn">
                <button onClick={handleMoveToFolderList} className="Move-btn">
                  Move to Table Folder List
                </button>
                <div className="download-buttons">
                  <select
                    onChange={(e) => {
                      if (e.target.value === "excel") {
                        handleDownload();
                      } else if (e.target.value === "pdf") {
                        handleDownloadPDF();
                      }
                    }}
                    className="download-select"
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Download As
                    </option>
                    <option value="excel">Excel (.xlsx)</option>
                    <option value="pdf">PDF (.pdf)</option>
                  </select>
                </div>
                {/* Spinner and Save button */}
                {isSaving && (
                  <div
                    style={{
                      position: "fixed",
                      top: "10px",
                      right: "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      backgroundColor: "#ffb402",
                      color: "#fff",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                      zIndex: 1000
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        border: "2px solid #ffffff",
                        borderTop: "2px solid transparent",
                        borderRadius: "50%",
                        animation: "spin 1s linear infinite"
                      }}
                    ></div>
                    Saving.
                  </div>
                )}
                <button
                  onClick={handleSave}
                  className="btn btn-primary"
                  disabled={isSaving}
                  style={{ position: "relative" }}
                >
                  {isSaving ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px"
                      }}
                    >
                      <div
                        style={{
                          width: "15px",
                          height: "15px",
                          border: "2px solid #ffffff",
                          borderTop: "2px solid transparent",
                          borderRadius: "50%",
                          animation: "spin 1s linear infinite"
                        }}
                      ></div>
                      Saving...
                    </div>
                  ) : (
                    <FaSave />
                  )}
                </button>
                <button onClick={handleUndo} className="btn btn-secondary">
                  <FaUndo />
                </button>
                <button
                  onClick={() => handleAddRow(selectedCell.rowIndex)}
                  className="btn btn-success"
                >
                  <FaPlus />
                </button>
                <button onClick={toggleRowNumbers} className="btn btn-info">
                  {showRowNumbers ? "Hide" : "Show"}
                </button>
              </div>
            </div>

            {showSuccess && (
              <div
                style={{
                  position: "fixed",
                  top: "20px",
                  right: "20px",
                  backgroundColor: "#4CAF50",
                  color: "white",
                  padding: "15px 25px",
                  borderRadius: "5px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  animation: "slideIn 0.5s ease-out",
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#4CAF50",
                    fontWeight: "bold"
                  }}
                >
                  ✓
                </div>
                Data saved successfully!
                <style>
                  {`
          @keyframes slideIn {
            from { transform: translateX(100%); opacity: 0; }
            to { transform: translateX(0); opacity: 1; }
          }
        `}
                </style>
              </div>
            )}
          </header>

          <table
            className="table table-bordered"
            id={getTableId(tableData ? tableData.name : "")}
          >
            <tbody>
              {tableData &&
                tableData.rows.map((rowData, rowIndex) =>
                  createRow(rowData, rowIndex)
                )}
            </tbody>
          </table>
        </>
      )}
      {contextMenu && (
        <ul
          className="context-menu"
          style={{ top: contextMenu.top, left: contextMenu.left }}
        >
          {/* <li onClick={() => handleContextMenuAction("addAbove")}>
            <FaPlus /> Add Row Above
          </li> */}
          <li onClick={() => handleContextMenuAction("addBelow")}>
            <FaPlus /> Add New Row
          </li>
          {/* <li onClick={() => handleContextMenuAction("removeAbove")}>
            <FaMinus /> Remove Row Above
          </li>
          <li onClick={() => handleContextMenuAction("removeBelow")}>
            <FaMinus /> Remove Row Below
          </li> */}
          <li onClick={() => handleRemoveRow(contextMenu.rowIndex)}>
            <FaTrashAlt /> Remove Selected Row
          </li>
        </ul>
      )}
    </div>
  );
};

export default MyTable;
