// pages/Registration.js

import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import imgae from "../assets/login.png";

const Registration = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const location = useLocation();

  const handleRegister = () => {
    axios
      .post("https://vertical.lblfm.com/api/register", {
        username,
        email,
        password
      })
      .then(() => {
        setSuccess("Registration successful! Redirecting to login...");
        setTimeout(() => {
          window.location = "/login";
        }, 800); // Delay to show success message before redirecting
      })
      .catch((error) => {
        console.error(
          "API Error:",
          error.response ? error.response.data : error.message
        );
        setError("An error occurred. Please try again.");
      });
  };

  return (
    <div className="login-page">
      <div className="login-image">
        {/* Placeholder for any image */}
        <img src={imgae} alt="Registration Visual" />
      </div>
      <div className="login-container">
        <h2>Register</h2>
        <p>Create your account by filling out the form below.</p>

        {error && <div className="error-message">{error}</div>}
        {success && <div className="success-message">{success}</div>}

        <input
          type="text"
          className="input-field"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="email"
          className="input-field"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          className="input-field"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="login-button" onClick={handleRegister}>
          Register
        </button>
        <div className="register-text">
          Already have an account?{" "}
          <button
            className="register-button"
            onClick={() => (window.location = "/login")}
          >
            Login here
          </button>
        </div>
      </div>
    </div>
  );
};

export default Registration;
